/**
 * Return VWO Variation Running on the Page
 * @param {String} id - VWO Test ID
 * @returns {String} - VWO Variation Name
 */

export default function activeVariationByCampaign(id = null) {
	let variation = null;

	if (window._vwo_campaignData && Object.keys(window._vwo_campaignData).length !== 0) {
		const campaignId = id || Object.keys(window._vwo_campaignData)[0],
			campaignData = window._vwo_campaignData[campaignId];

		variation = campaignData.n;
	}

	return variation;
}