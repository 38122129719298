
function initDomHelpers() {
	HTMLElement.prototype.slideUp = function(duration = 300, updateTabIndex = false, addAriaHidden = false) {
		if (!this) {
			return;
		}

		this.style.display = '';
		this.style.transition = `height ${duration}ms ease-in-out`;
		this.style.overflow = 'auto';
		this.style.height = `${this.scrollHeight}px`;

		if (updateTabIndex) {
			this.removeAttribute('tabindex');
		}
		if (addAriaHidden) {
			this.setAttribute('aria-hidden', false);
		}

		return this;
	};

	HTMLElement.prototype.slideDown = function(duration = 300, updateTabIndex = false, addAriaHidden = false) {
		if (!this) {
			return;
		}

		this.style.transition = `height ${duration}ms ease-in-out`;
		this.style.overflow = 'hidden';
		this.style.height = '0px';
		this.style.display = 'none';

		if (updateTabIndex) {
			this.setAttribute('tabindex', '-1');
		}
		if (addAriaHidden) {
			this.setAttribute('aria-hidden', true);
		}

		return this;
	};

	HTMLElement.prototype.fadeIn = function(duration = 200, updateTabIndex = false, addAriaHidden = false) {
		if (!this) {
			return;
		}

		this.style.transition = `${duration}ms ease-in-out`;
		this.style.overflow = 'hidden';
		this.style.opacity = '1';

		if (updateTabIndex) {
			this.setAttribute('tabindex', '0');
		}
		if (addAriaHidden) {
			this.setAttribute('aria-hidden', false);
		}

		return this;
	};

	HTMLElement.prototype.fadeOut = function(duration = 200, updateTabIndex = false, addAriaHidden = false) {
		if (!this) {
			return;
		}

		this.style.transition = `${duration}ms ease-in-out`;
		this.style.overflow = 'hidden';
		this.style.opacity = '0';

		if (updateTabIndex) {
			this.setAttribute('tabindex', '-1');
		}
		if (addAriaHidden) {
			this.setAttribute('aria-hidden', true);
		}

		return this;
	};

	HTMLElement.prototype.show = function(updateTabIndex = true, addAriaHidden = true) {
		if (!this) {
			return;
		}

		this.style.display = '';
		if (updateTabIndex) {
			this.removeAttribute('tabindex');
		}
		if (addAriaHidden) {
			this.setAttribute('aria-hidden', false);
		}
		return this;
	};

	HTMLElement.prototype.hide = function(updateTabIndex = true, addAriaHidden = true) {
		if (!this) {
			return;
		}

		this.style.display = 'none';
		if (updateTabIndex) {
			this.setAttribute('tabindex', '-1');
		}
		if (addAriaHidden) {
			this.setAttribute('aria-hidden', true);
		}
		return this;
	};

	HTMLElement.prototype.addClass = function(classes) {
		if (!this) {
			return;
		}

		this.classList.add(classes);

		return this;
	};

	HTMLElement.prototype.removeClass = function(classes) {
		if (!this) {
			return;
		}

		this.classList.remove(classes);
		return this;
	};

	HTMLElement.prototype.html = function(html) {
		if (!this) {
			return;
		}

		if (html) {
			this.innerHTML = html;
		} else {
			return this.innerHTML;
		}

		return this;
	};
}

const domHelperAttrName = 'data-domhelpers';
const shouldUseDomHelpers = document.body.hasAttribute(domHelperAttrName) && document.body.getAttribute(domHelperAttrName) === 'true';

if (shouldUseDomHelpers) {
	initDomHelpers();
}
