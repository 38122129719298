/**
 * This function returns the center coordinates (x, y) for a given state 
 * based on its location on an SVG map of the United States, 
 * with the map size set to 367x200 pixels.
 * SVG map: https://quinstreet.atlassian.net/wiki/spaces/FEE/pages/9355657288/US+Map+Svg
 * @param {String} code - State code to return its center coordinate
 * @returns {Object} - State's center coordinate to plot a pin
 */
export default function returnPinCoordinateForState(code) {
	const pinCoordinatesForStates = {
		'AL': { x: 278, y: 122 },
		'AK': { x: 80, y: 159 },
		'AZ': { x: 118, y: 112 },
		'AR': { x: 239, y: 111 },
		'CA': { x: 76, y: 85 },
		'CO': { x: 159, y: 82 },
		'CT': { x: 345, y: 37 },
		'DE': { x: 337, y: 63 },
		'FL': { x: 317, y: 152 },
		'GA': { x: 300, y: 120 },
		'HI': { x: 153, y: 176 },
		'ID': { x: 114, y: 41 },
		'IL': { x: 256, y: 69 },
		'IN': { x: 273, y: 68 },
		'IA': { x: 229, y: 59 },
		'KS': { x: 202, y: 86 },
		'KY': { x: 284, y: 85 },
		'LA': { x: 241, y: 137 },
		'ME': { x: 352, y: 4 },
		'MD': { x: 327, y: 61 },
		'MA': { x: 347, y: 30 },
		'MI': { x: 275, y: 41 },
		'MN': { x: 221, y: 28 },
		'MS': { x: 258, y: 125 },
		'MO': { x: 237, y: 84 },
		'MT': { x: 144, y: 20 },
		'NE': { x: 194, y: 63 },
		'NV': { x: 96, y: 69 },
		'NH': { x: 345, y: 21 },
		'NJ': { x: 336, y: 48 },
		'NM': { x: 154, y: 116 },
		'NY': { x: 330, y: 30 },
		'NC': { x: 320, y: 92 },
		'ND': { x: 189, y: 19 },
		'OH': { x: 291, y: 63 },
		'OK': { x: 210, y: 108 },
		'OR': { x: 82, y: 29 },
		'PA': { x: 320, y: 51 },
		'RI': { x: 350, y: 34 },
		'SC': { x: 313, y: 108 },
		'SD': { x: 191, y: 41 },
		'TN': { x: 277, y: 100 },
		'TX': { x: 201, y: 140 },
		'UT': { x: 125, y: 77 },
		'VT': { x: 338, y: 18 },
		'VA': { x: 320, y: 77 },
		'WA': { x: 90, y: 6 },
		'WV': { x: 306, y: 73 },
		'WI': { x: 246, y: 37 },
		'WY': { x: 150, y: 50 }
	};

	return pinCoordinatesForStates[code];
}
